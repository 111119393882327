import React from 'react';
import PropTypes from 'prop-types';

import { BackgroundPictureWrapper } from './BackgroundPicture.style';

const propTypes = {
  fluid: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      src: PropTypes.string,
    }),
  ]).isRequired,
  portrait: PropTypes.bool,
  fix: PropTypes.bool,
  border: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  fix: false,
  border: false,
  portrait: false,
  className: 'BackgroundImage',
};

const BackgroundPicture = ({ className, fluid, portrait, fix, border }) => (
  <BackgroundPictureWrapper
    className={className}
    fluid={fluid}
    portrait={portrait}
    border={border ? 1 : 0}
    fix={fix}
  />
);

BackgroundPicture.propTypes = propTypes;
BackgroundPicture.defaultProps = defaultProps;

export default BackgroundPicture;

import React from 'react';
import PropTypes from 'prop-types';

import RightContainerWrapper from './RightContainer.style';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  padding: PropTypes.bool,
  reverseMobile: PropTypes.bool,
  column: PropTypes.bool,
};

const defaultProps = {
  padding: false,
  reverseMobile: false,
  column: false,
};

const Container = ({ children, padding, reverseMobile, column }) => (
  <RightContainerWrapper padding={padding} reverseMobile={reverseMobile} column={column}>
    {children}
  </RightContainerWrapper>
);

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;
export default Container;

import Styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

import { colors, breakpoints } from '../../styles/variables';

// eslint-disable-next-line import/prefer-default-export
export const BackgroundPictureWrapper = Styled(BackgroundImage)`
  width: 100%;
  height: ${({ portrait }) => (portrait ? '50rem' : '30rem')};
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  ${({ border }) => border && `border: .4rem solid ${colors.lightAccent}`};

  @media ${breakpoints.desktop} {
    :before, :after {
      background-attachment: ${({ fix }) => (fix ? 'fixed' : 'scroll')};
    }
  }
`;

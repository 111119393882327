import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Container from '../Container/Container';
import LeftContainer from '../LeftContainer/LeftContainer';
import RightContainer from '../RightContainer/RightContainer';
import Button from '../Button/Button';
import {
  HeadingContainer,
  StyledParagraph,
  StyledPicture,
  StyledBackgroundPicture,
} from './Promo.style';

const propTypes = {
  mirrored: PropTypes.bool,
  paragraph: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  image: PropTypes.shape({}).isRequired,
  logo: PropTypes.shape({}).isRequired,
};

const defaultProps = {
  mirrored: false,
  buttonLink: null,
  paragraph: null,
  buttonText: null,
};

const Promo = ({ mirrored, paragraph, buttonText, buttonLink, image, logo }) => (
  <Container mirrored={mirrored} flexStart>
    <LeftContainer>
      <StyledBackgroundPicture fix border fluid={image} />
      <StyledPicture fixed={logo} />
    </LeftContainer>
    <RightContainer>
      <HeadingContainer>
        {paragraph && <StyledParagraph>{paragraph}</StyledParagraph>}
        {buttonText && buttonLink && (
          <Link aria-label="kontakt" to={buttonLink}>
            <Button appearance="primary">{buttonText}</Button>
          </Link>
        )}
      </HeadingContainer>
    </RightContainer>
  </Container>
);

Promo.propTypes = propTypes;
Promo.defaultProps = defaultProps;

export default Promo;

import Styled from 'styled-components';

import { breakpoints } from '../../styles/variables';

const LeftContainerWrapper = Styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  flex: 3;
  width: inherit;
  height: inherit;

  @media ${breakpoints.TABLET_PORTRAIT} {
    justify-content: center;
    text-align: left;
  }
`;

export default LeftContainerWrapper;

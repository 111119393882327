import React from 'react';

import Hero from '../../components/Hero/Hero';
import Promo from '../../components/Promo/Promo';

import { getEventsPage } from './offer.query';

const Events = () => {
  const data = getEventsPage();

  const { offerHeroHeading, offerHeroImages, offerPromo } = data.allWordpressPage.edges[0].node.acf;

  return (
    <>
      <Hero heading={offerHeroHeading} slides={offerHeroImages} />
      {offerPromo &&
        offerPromo.map(({ buttonLink, buttonText, description, logo, image, mirrored }, index) => (
          <Promo
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            logo={logo.localFile.childImageSharp.fixed}
            image={image.localFile.childImageSharp.fluid}
            paragraph={description}
            buttonText={buttonText}
            buttonLink={buttonLink}
            mirrored={mirrored}
          />
        ))}
    </>
  );
};

export default Events;

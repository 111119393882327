import React from 'react';
import PropTypes from 'prop-types';

import LeftContainerWrapper from './LeftContainer.style';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  padding: PropTypes.bool,
  reverseMobile: PropTypes.bool,
  column: PropTypes.bool,
};

const defaultProps = {
  padding: false,
  reverseMobile: false,
  column: false,
};

const LeftContainer = ({ children, padding, reverseMobile, column }) => (
  <LeftContainerWrapper padding={padding} reverseMobile={reverseMobile} column={column}>
    {children}
  </LeftContainerWrapper>
);

LeftContainer.propTypes = propTypes;
LeftContainer.defaultProps = defaultProps;
export default LeftContainer;

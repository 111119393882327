import { useStaticQuery, graphql } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const getEventsPage = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { id: { eq: "514d5a89-60f5-52be-bdef-b0964cd2edb7" } }) {
        edges {
          node {
            title
            slug
            acf {
              offerHeroHeading
              offerHeroImages {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              offerPromo {
                buttonLink
                buttonText
                mirrored
                description
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                logo {
                  localFile {
                    childImageSharp {
                      fixed(height: 110) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

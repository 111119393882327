import Styled from 'styled-components';

import { breakpoints } from '../../styles/variables';

const RightContainerWrapper = Styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 2;

  @media ${breakpoints.TABLET_PORTRAIT} {
    justify-content: center;
    align-content: space-around;
    text-align: left;
  }
`;

export default RightContainerWrapper;

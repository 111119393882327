import Styled from 'styled-components';

import BackgroundPicture from '../BackgroundPicture/BackgroundPicture';
import Picture from '../Picture/Picture';

import { Paragraph } from '../../styles/commonStyles';

import { breakpoints, fonts, colors } from '../../styles/variables';

export const StyledParagraph = Styled(Paragraph)`
  padding: 3rem 0;
`;

export const Heading = Styled.span`
  position: absolute;
  font-size: 8rem;
  font-family: ${fonts.merriweatherBold};
  color: ${colors.lightShade};
`;

export const HeadingContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 80%;
  padding: 6rem 0 10rem 0;

  @media ${breakpoints.tabletLandscape} {
    transform: none;
    padding: 0;
    height: 84vh;
    margin-top: -10rem;
  }
`;

export const StyledPicture = Styled(Picture)`
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);

  @media ${breakpoints.tabletLandscape} {
    top: calc(50% - 5rem);
  }
`;

export const StyledBackgroundPicture = Styled(BackgroundPicture)`
  @media ${breakpoints.tabletLandscape} {
    height: 84vh;
    margin-top: -10rem;
  }
`;
